<script>

  import axios from 'axios'
  import VuePhoneNumberInput from 'vue-phone-number-input';

  export default {
    components: { VuePhoneNumberInput },
    props: ['form_id'],
    data() {
      return {
        loading: true,
        config: {},
        subscribing: false,
        hasError: false,
        errorMsg: "",
        mobileNumber: null,
        validMobileNumber: false,
        hasExistingEnrollment: false,
        loadingZipcode: false,
        subscriberStatus: 'unsubscribed',
        submitSuccess: false,
        onlyCountries: ['US', 'CA'],
        customText: {
          countrySelectorLabel: 'Country code',
          countrySelectorError: 'Select a Country',
          phoneNumberLabel: 'Mobile Number',
          example: 'Example :'
        },
        email: null,
        dob_month: null,
        dob_day: null,
        dob_year: null,
        first_name: null,
        last_name: null,
        address: null,
        address_2: null,
        city: null,
        state: null,
        zipcode: null,
        country: null,
        customAnswers: [],
        stripeKey: '',
        stripeToken: null,
        stripeStyle: {
          base: {
            fontSize: "16px"
          }
        }
      }
    },
    computed: {
      cssProps() {
        if( !this.config ){
          return null;
        }
        return {
          '--button-submit-bg': this.config.styles.buttons.submit.backgroundColor,
          '--button-submit-bg-hover': this.config.styles.buttons.submit.backgroundColorHover,
          '--button-submit-color': this.config.styles.buttons.submit.textColor,
          '--button-submit-color-hover': this.config.styles.buttons.submit.textColorHover
        }
      },
      urlParams(){
        const queryString = window.location.search;
        return new URLSearchParams(queryString);
      },
      enabledCustomFields() {
        if (this.config && this.config.fields.custom_fields) {
          return this.config.fields.custom_fields.filter((field) => {
            return field.enabled
          });
        }
        return []
      },
      showHeaders(){
        if( typeof this.config.show_headers === 'undefined' || this.config.show_headers ){
          return true;
        }

        return false;
      },
      showLabels(){
        if( typeof this.config.show_labels === 'undefined' || this.config.show_labels ){
          return true;
        }
        return false;
      },
      dobDays(){
        return ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];
      },
      dobYears(){
        const currentYear = (new Date()).getFullYear();
        const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
        return range(currentYear, currentYear - 125, -1);
      },
      dobMonths() {
        return [
          {
            text: "01 - January",
            value: "01"
          },
          {
            text: "02 - February",
            value: "02"
          },
          {
            text: "03 - March",
            value: "03"
          },
          {
            text: "04 - April",
            value: "04"
          },
          {
            text: "05 - May",
            value: "05"
          },
          {
            text: "06 - June",
            value: "06"
          },
          {
            text: "07 - July",
            value: "07"
          },
          {
            text: "08 - August",
            value: "08"
          },
          {
            text: "09 - September",
            value: "09"
          },
          {
            text: "10 - October",
            value: "10"
          },
          {
            text: "11 - November",
            value: "11"
          },
          {
            text: "12 - December",
            value: "12"
          }
        ]
      },
      stateListUSA() {
        return [
          {
            text: "Alabama",
            value: "AL"
          },
          {
            text: "Alaska",
            value: "AK"
          },
          {
            text: "American Samoa",
            value: "AS"
          },
          {
            text: "Arizona",
            value: "AZ"
          },
          {
            text: "Arkansas",
            value: "AR"
          },
          {
            text: "Armed Forces - Americas",
            value: "AA"
          },
          {
            text: "Armed Forces - Europe/Africa/Canada",
            value: "AE"
          },
          {
            text: "Armed Forces - Pacific",
            value: "AP"
          },
          {
            text: "California",
            value: "CA"
          },
          {
            text: "Colorado",
            value: "CO"
          },
          {
            text: "Connecticut",
            value: "CT"
          },
          {
            text: "Delaware",
            value: "DE"
          },
          {
            text: "District of Columbia",
            value: "DC"
          },
          {
            text: "Federated States of Micronesia",
            value: "FM"
          },
          {
            text: "Florida",
            value: "FL"
          },
          {
            text: "Georgia",
            value: "GA"
          },
          {
            text: "Hawaii",
            value: "HI"
          },
          {
            text: "Idaho",
            value: "ID"
          },
          {
            text: "Illinois",
            value: "IL"
          },
          {
            text: "Indiana",
            value: "IN"
          },
          {
            text: "Iowa",
            value: "IA"
          },
          {
            text: "Kansas",
            value: "KS"
          },
          {
            text: "Kentucky",
            value: "KY"
          },
          {
            text: "Louisiana",
            value: "LA"
          },
          {
            text: "Maine",
            value: "ME"
          },
          {
            text: "Marshall Islands",
            value: "MH"
          },
          {
            text: "Maryland",
            value: "MD"
          },
          {
            text: "Massachusetts",
            value: "MA"
          },
          {
            text: "Michigan",
            value: "MI"
          },
          {
            text: "Minnesota",
            value: "MN"
          },
          {
            text: "Mississippi",
            value: "MS"
          },
          {
            text: "Missouri",
            value: "MO"
          },
          {
            text: "Montana",
            value: "MT"
          },
          {
            text: "Nebraska",
            value: "NE"
          },
          {
            text: "Nevada",
            value: "NV"
          },
          {
            text: "New Hampshire",
            value: "NH"
          },
          {
            text: "New Jersey",
            value: "NJ"
          },
          {
            text: "New Mexico",
            value: "NM"
          },
          {
            text: "New York",
            value: "NY"
          },
          {
            text: "North Carolina",
            value: "NC"
          },
          {
            text: "North Dakota",
            value: "ND"
          },
          {
            text: "Northern Mariana Islands",
            value: "MP"
          },
          {
            text: "Ohio",
            value: "OH"
          },
          {
            text: "Oklahoma",
            value: "OK"
          },
          {
            text: "Oregon",
            value: "OR"
          },
          {
            text: "Palau",
            value: "PW"
          },
          {
            text: "Pennsylvania",
            value: "PA"
          },
          {
            text: "Puerto Rico",
            value: "PR"
          },
          {
            text: "Rhode Island",
            value: "RI"
          },
          {
            text: "South Carolina",
            value: "SC"
          },
          {
            text: "South Dakota",
            value: "SD"
          },
          {
            text: "Tennessee",
            value: "TN"
          },
          {
            text: "Texas",
            value: "TX"
          },
          {
            text: "Utah",
            value: "UT"
          },
          {
            text: "Vermont",
            value: "VT"
          },
          {
            text: "Virgin Islands",
            value: "VI"
          },
          {
            text: "Virginia",
            value: "VA"
          },
          {
            text: "Washington",
            value: "WA"
          },
          {
            text: "West Virginia",
            value: "WV"
          },
          {
            text: "Wisconsin",
            value: "WI"
          },
          {
            text: "Wyoming",
            value: "WY"
          }
        ];
      },
      stateListCAN() {
        return [
          {
            text: "Alberta",
            value: "AB"
          },
          {
            text: "British Columbia",
            value: "BC"
          },
          {
            text: "Manitoba",
            value: "MB"
          },
          {
            text: "New Brunswick",
            value: "NB"
          },
          {
            text: "Newfoundland",
            value: "NL"
          },
          {
            text: "Northwest Territories",
            value: "NT"
          },
          {
            text: "Nova Scotia",
            value: "NS"
          },
          {
            text: "Nunavut",
            value: "NU"
          },
          {
            text: "Ontario",
            value: "ON"
          },
          {
            text: "Prince Edward Island",
            value: "PE"
          },
          {
            text: "Quebec",
            value: "QC"
          },
          {
            text: "Saskatchewan",
            value: "SK"
          },
          {
            text: "Yukon",
            value: "YT"
          }
        ];
      },
      utmParams(){
        let params = '?utm_medium=poweredby';

        if( this.form_id ){
          params += '&utm_campaign=embedded_'+this.form_id;
        }

        if( window.location.hostname ){
          params += '&utm_source='+window.location.hostname;
        }

        return params;
      }
    },
    created() {
      this.fetchData();
    },
    methods: {
      fetchData(){

        axios.get('https://api.textretailer.com/form/'+this.form_id+'/settings')
          .then(response => {
            this.config = response.data;

            // Populate customField Answers
            if (
              this.enabledCustomFields &&
              this.enabledCustomFields.length > 0
            ) {

              let that = this

              // Generate Custom Form Fields & Answer Object
              this.enabledCustomFields.forEach(function(enabledField) {

                // Find matching customField object
                let formField = that.config.customFields.find((customField) => {
                  return customField.field_id === enabledField.field_id
                });

                // Generate empty answer object
                if( formField ){

                  // Associate field
                  enabledField.formField = formField;

                  switch( formField.type ){
                    case 'choices_multi':
                    case 'choices_single':

                      that.customAnswers.push({
                        field_id: formField.field_id,
                        type: formField.type,
                        natural_key: formField.api_key,
                        label: formField.name,
                        selection: formField.selection,
                        options: formField.choices,
                        value: [],
                      })

                      break;

                    default:
                      that.customAnswers.push({
                        field_id: formField.field_id,
                        type: formField.type,
                        natural_key: formField.api_key,
                        label: formField.name,
                        value: null,
                      })
                      break;
                  }

                }

              })

            }

            // Generate Custom Fields & Answer Object

            // if( this.config.custom_fields && this.config.custom_fields.length > 0 ){
            //
            //   let that = this;
            //
            //   this.config.custom_fields.forEach(function(field){
            //
            //     if( field.type === 'multichoice' ){
            //       that.customAnswers.push({
            //         'field_id': field.id,
            //         'type': field.type,
            //         'natural_key': field.natural_key,
            //         'label': field.label,
            //         'selection': field.selection,
            //         'options': field.options,
            //         'value': []
            //       });
            //
            //     } else {
            //       that.customAnswers.push({
            //         'field_id': field.id,
            //         'type': field.type,
            //         'natural_key': field.natural_key,
            //         'label': field.label,
            //         'value': null
            //       });
            //     }
            //
            //   });
            //
            // }

            this.$nextTick(function() {

              if( this.config.fields.credit_card_stripe && this.config.payment_platform === 'stripe' ){

                // Load Test Account
                if (this.config.business_id === 1000) {
                  this.stripeKey = "pk_test_51I49MOHpMQh8r62XM3ASYi4N0Ur2HEs1lfYfpPen9Osn5EZKf8AYeiPvObeH42xCkmS52sAcUJ5VKM9jWKirHI6t00SiRyVUbn";

                // Load Live Account
                } else {
                  this.stripeKey = "pk_live_51I49MOHpMQh8r62XeVRkXKO32VtGPrnR7hnarvjXJFEQbFd3KYSXWReMoXTKAybvBvOIu0H8AmW7R4EqQ6rEF7ug00wouQ9FZ7";
                }

                // Funky way to load stipe script & elements module
                this.includeJS('js.stripe.com/v3/', function(){
                  this.includeModule('unpkg.com/@power-elements/stripe-elements/stripe-elements.js?module', function() {

                    let that = this;
                    setTimeout(function(){
                      that.loading = false;
                    },250);

                  }.bind(this) );
                }.bind(this) );

              } else {
                this.loading = false;
              }

              axios.post('https://api.textretailer.com/profile/embedded/'+this.form_id+'/view');

            });

          });

      },
      validateMobile(number){
        this.hasExistingEnrollment = false;
        this.subscriberStatus = 'unknown';
        this.validMobileNumber = number.isValid;

        if( number.isValid ){
          this.lookupPhone(number.formattedNumber);
        }
      },
      lookupPhone(formattedNumber){

        if( !this.validMobileNumber ){
          return false;
        }

        axios.get('https://api.textretailer.com/lookup/account/'+this.config.business_code+'/'+formattedNumber)
          .then((response) => {
            this.hasExistingEnrollment = response.data.has_enrollment;
            this.subscriberStatus = response.data.status;
          });

      },
      lookupZipcode(){

        this.city = null;
        this.state = null;
        this.country = null;

        if( !this.zipcode || this.zipcode === '' ){
          return false;
        }

        this.loadingZipcode = true;

        axios.get('https://api.textretailer.com/lookup/zipcode/'+this.zipcode)
          .then((response) => {
            this.city = response.data.city;
            this.state = response.data.state;
            this.country = response.data.country;
            this.loadingZipcode = false;
          })
          .catch(() => {
            this.loadingZipcode = false;
          });

      },
      submitForm() {

        this.hasError = false;
        this.errorMsg = "";

        if( this.config.fields.credit_card_stripe && this.config.payment_platform === 'stripe' ){

          this.$refs.stripeCardInput.submit()
            .then((result) => {

              if (result.error) {
                this.hasError = true;
                this.errorMsg = "Oops! There was an error saving your credit card information. Please contact support@textretailer.com";
                this.subscribing = false;

                return;
              }

              this.stripeToken = result.token;
              this.createSubscriber();

            })
            .catch((error) => {

              if(error.message){
                this.errorMsg = error.message;
              } else {
                this.errorMsg = "Oops! There was an error saving your credit card information. Please contact support@textretailer.com";
              }
              this.hasError = true;
              this.subscribing = false;
            });

        } else {
          this.createSubscriber();
        }

      },
      createSubscriber(){

        this.subscribing = true;
        this.hasError = false;

        let postData = {
          'form_id': this.form_id,
          'form_url': window.location.href,
          'business_id': this.config.business_id,
          'sms_number': this.mobileNumber,
          'email': this.email,
          'dob_month': this.dob_month,
          'dob_day': this.dob_day,
          'dob_year': this.dob_year,
          'first_name': this.first_name,
          'last_name': this.last_name,
          'address': this.address,
          'address_2': this.address_2,
          'city': this.city,
          'state': this.state,
          'zipcode': this.zipcode,
          'country': this.country,
          'customAnswers': this.customAnswers,
          'config': this.config
        };

        if( this.urlParams.get('trRef') ){
          postData.referral_code = this.urlParams.get('trRef');
        }

        if( this.stripeToken ){
          postData.stripe_token = this.stripeToken;
        }

        axios.post('https://api.textretailer.com/profile/embedded/'+this.form_id, postData)
          .then(() => {
            this.subscribing = false;
            this.submitSuccess = true;
          })
          .catch((error) => {

            if( error.response.data.error === 'account_already_exists' ) {
              this.hasError = true;
              this.errorMsg = 'It looks like you already have an account.<br /><a class="alert-link" href="/login">Please login</a> to update your exsting profile.';

            } else if( error.response.data.error === 'invalid_birthdate' ) {
              this.hasError = true;
              this.errorMsg = error.response.data.message;

            } else {
              this.hasError = true;
              this.errorMsg = "Oops! The phone number is invalid.<br />TextRetailer only supports US & Canadian phone numbers.";
            }

            this.subscribing = false;

          });


      },
      includeJS( URL, callback ){
        let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },
      includeModule( URL, callback ){
        let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.type = "module";
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
      },
    }
  }
</script>

<template>

  <div>

    <div v-if="loading" class="mt-5" style="text-align: center;">
      <p class="tr-subheader"><a :href="'https://textretailer.com'+utmParams" style="color: #686868; text-decoration: none;">Loading...</a></p>
    </div>

    <div v-if="!loading" class="tr-container" :style="cssProps">

      <div class="tr-form" :class="{ 'simple': config.style === 'simple' }">

        <template v-if="!submitSuccess">

          <div class="tr-header-container" v-if="config.header || config.subheader">
            <h2 v-if="config.header" class="tr-header" v-html="config.header"></h2>
            <p v-if="config.subheader" class="tr-subheader" v-html="config.subheader"></p>
          </div>

          <form @submit.prevent="submitForm" class="px-5">

            <div class="tr-field-row">
              <div class="tr-phone">
                <label class="tr-label" for="trMobileNumber" v-if="showLabels">Mobile Number</label>
                <vue-phone-number-input
                  id="trMobileNumber"
                  ref="phoneNumberInput"
                  :only-countries="onlyCountries"
                  default-country-code="US"
                  :translations="customText"
                  v-model="mobileNumber"
                  @update="validateMobile"
                  required
                />
              </div>
            </div>

            <template v-if="!hasExistingEnrollment">

              <div class="tr-field-row" v-if="config.fields && config.fields.email">
                <div class="tr-field">
                  <label class="tr-label" for="trEmail" v-if="showLabels">Email Address</label>
                  <input class="tr-form-control" id="trEmail" type="email" placeholder="Email Address" required="required" aria-required="true" v-model="email">
                </div>
              </div>

              <template v-if="config.fields && config.fields.dob">

                <p style="margin-bottom:-.7rem"><strong>Date of Birth</strong></p>

                <div class="tr-flex-between">

                  <div class="tr-field tr-field-med">
                      <label class="tr-label" for="dobMonth" v-if="showLabels">Month</label>
                      <select class="tr-form-control" id="dobMonth" required="required" aria-required="true" v-model="dob_month">
                          <option :value="null">-- Select Month --</option>
                          <option v-for="option in dobMonths" :value="option.value" :key="option.value">{{ option.text }}</option>
                      </select>
                  </div>

                  <div class="tr-field tr-field-med">
                      <label class="tr-label" for="dobDay" v-if="showLabels">Day</label>
                      <select class="tr-form-control" id="dobDay" required="required" aria-required="true" v-model="dob_day">
                          <option :value="null">-- Select Day --</option>
                          <option v-for="option in dobDays" :value="option" :key="option">{{ option }}</option>
                      </select>
                  </div>

                  <div class="tr-field tr-field-med">
                      <label class="tr-label" for="dobYear" v-if="showLabels">Year</label>
                      <select class="tr-form-control" id="dobYear" required="required" aria-required="true" v-model="dob_year">
                          <option :value="null">-- Select Year --</option>
                          <option v-for="option in dobYears" :value="option" :key="option">{{ option }}</option>
                      </select>
                  </div>

                </div>

              </template>

              <h4 class="tr-section-header" v-if="showHeaders && config.fields && config.fields.shipping">
                Where can we ship your orders?
              </h4>

              <div class="tr-field-row" v-if="config.fields && (config.fields.first_name || config.fields.last_name)">

                <div class="tr-field" v-if="config.fields && config.fields.first_name">
                  <label class="tr-label" for="trFname" v-if="showLabels">First Name</label>
                  <input class="tr-form-control" id="trFname" type="text" placeholder="First Name" required="required" aria-required="true" v-model="first_name">
                </div>

                <div class="tr-field" v-if="config.fields && config.fields.last_name">
                  <label class="tr-label" for="trLname" v-if="showLabels">Last Name</label>
                  <input class="tr-form-control" id="trLname" type="text" placeholder="Last Name" required="required" aria-required="true" v-model="last_name">
                </div>

              </div>

              <template v-if="config.fields && config.fields.shipping">

                <div class="tr-field-row">
                  <div class="tr-field">
                    <label class="tr-label" for="trAddress" v-if="showLabels">Shipping Address</label>
                    <input class="tr-form-control" id="trAddress" type="text" placeholder="Address Line 1" required="required" aria-required="true" v-model="address">
                  </div>

                  <div class="tr-field">
                    <label class="tr-label" for="trAddress2" v-if="showLabels">Shipping Address Line 2</label>
                    <input class="tr-form-control" id="trAddress2" type="text" placeholder="Apartment, suite, etc. (optional)" v-model="address_2">
                  </div>
                </div>

              </template>

              <template v-if="config.fields && (config.fields.shipping || config.fields.zipcode)">

                <div class="tr-field-row">
                  <div class="tr-field tr-field-small">
                    <label class="tr-label" for="trZipcode" v-if="showLabels">Zip Code</label>
                    <input class="tr-form-control" id="trZipcode" type="text" placeholder="Zip Code" required="required" aria-required="true" v-model="zipcode" @change="lookupZipcode">
                  </div>

                  <div class="tr-field">
                    <label class="tr-label" for="trCity" v-if="showLabels">City</label>
                    <input class="tr-form-control" id="trCity" type="text" placeholder="City" required="required" aria-required="true" v-model="city" :disabled="loadingZipcode">
                  </div>

                  <div class="tr-field tr-field-small">
                    <label class="tr-label" for="trState" v-if="showLabels">State</label>
                    <select class="tr-form-control" id="trState" required="required" aria-required="true" v-model="state" :disabled="loadingZipcode">
                      <option :value="null">-- Select State --</option>
                      <optgroup label="United States">
                        <option v-for="option in stateListUSA" :value="option.value" :key="option.value">{{ option.text }}</option>
                      </optgroup>
                      <optgroup label="Canada">
                        <option v-for="option in stateListCAN" :value="option.value" :key="option.value">{{ option.text }}</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
              </template>

              <template v-if="enabledCustomFields && enabledCustomFields.length > 0">

                <div class="tr-field-row" v-for="(field, index) in enabledCustomFields" :key="field.field_id">

                  <div class="tr-field tr-field-full">

                    <label class="tr-label" :for="field.field_id" v-if="showLabels">{{ field.formField.name }}</label>

                    <input
                      v-if="field.formField.type === 'text'"
                      :id="field.field_id"
                      class="tr-form-control"
                      type="text"
                      :placeholder="field.formField.name"
                      :required="field.formField.required_for_purchase"
                      v-model="customAnswers[index].value"
                    />

                    <input
                      v-if="field.formField.type === 'number'"
                      :id="field.field_id"
                      class="tr-form-control"
                      type="number"
                      :placeholder="field.formField.name"
                      :required="field.formField.required_for_purchase"
                      v-model="customAnswers[index].value"
                    />

                    <template v-if="field.formField.type === 'choices_multi'">
                      <div v-for="(choice, choice_index) in field.formField.choices" :key="field.field_id+'_'+choice_index">
                        <input
                          class="tr-checkbox"
                          type="checkbox"
                          :id="field.field_id+'_'+choice_index"
                          :name="field.field_id"
                          :value="choice"
                          v-model="customAnswers[index].value"
                        >
                        <label :for="field.field_id+'_'+choice_index">{{ choice }}</label>
                      </div>
                    </template>

                    <template v-if="field.formField.type === 'choices_single'">
                      <div v-for="(choice, choice_index) in field.formField.choices" :key="field.field_id+'_'+choice_index">
                        <input
                          class="tr-radio"
                          type="radio"
                          :id="field.field_id+'_'+choice_index"
                          :name="field.field_id"
                          :value="choice"
                          v-model="customAnswers[index].value"
                        >
                        <label :for="field.field_id+'_'+choice_index">{{ choice }}</label>
                      </div>
                    </template>

                  </div>
                </div>
              </template>

<!--              <template v-if="config.fields.custom && config.custom_fields && config.custom_fields.length > 0">-->

<!--                <div class="tr-field-row" v-for="(customField, index) in config.custom_fields" :key="customField.id">-->

<!--                  <div class="tr-field">-->

<!--                    <label class="tr-label" :for="customField.id" v-if="showLabels">{{ customField.label }}</label>-->

<!--                    <template v-if="customField.type === 'string'">-->
<!--                      <input-->
<!--                        class="tr-form-control"-->
<!--                        :id="customField.id"-->
<!--                        type="text"-->
<!--                        v-model="customAnswers[index].value"-->
<!--                        :placeholder="customField.label"-->
<!--                        :required="customField.purchase"-->
<!--                      />-->
<!--                    </template>-->

<!--                    <template v-if="customField.type === 'multichoice' && customField.selection === 'checkbox'">-->
<!--                      <div v-for="(option, option_index) in customField.options" :key="customField.id+'_'+option_index">-->
<!--                        <input-->
<!--                          class="tr-checkbox"-->
<!--                          type="checkbox"-->
<!--                          :id="customField.id+'_'+option_index"-->
<!--                          :name="customField.id"-->
<!--                          :value="option"-->
<!--                          v-model="customAnswers[index].value"-->
<!--                        >-->
<!--                        <label :for="customField.id+'_'+option_index">{{ option }}</label>-->
<!--                      </div>-->
<!--                    </template>-->

<!--                    <template v-if="customField.type === 'multichoice' && customField.selection === 'radio'">-->
<!--                      <div v-for="(option, option_index) in customField.options" :key="customField.id+'_'+option_index">-->
<!--                        <input-->
<!--                          class="tr-radio"-->
<!--                          type="radio"-->
<!--                          :id="customField.id+'_'+option_index"-->
<!--                          :name="customField.id"-->
<!--                          :value="option"-->
<!--                          v-model="customAnswers[index].value"-->
<!--                        >-->
<!--                        <label :for="customField.id+'_'+option_index">{{ option }}</label>-->
<!--                      </div>-->
<!--                    </template>-->

<!--                  </div>-->

<!--                </div>-->

<!--              </template>-->

              <template v-if="config.payment_platform === 'stripe' && config.fields && config.fields.credit_card_stripe">

                <div class="tr-stripe-container">

                  <h4 class="tr-section-header" v-if="showHeaders">
                    Payment Information
                  </h4>

                  <div class="tr-field-row">
                    <div class="tr-card">
                      <label class="tr-label">Credit or debit card</label>

                      <stripe-elements
                        ref="stripeCardInput"
                        generate="token"
                        :publishable-key="stripeKey"
                      ></stripe-elements>

                    </div>
                  </div>

                </div>


              </template>

            </template>

            <div class="tr-agreement">
              <input class="tr-agree-input" type="checkbox" value="" id="termsAgreement" required>
              <label class="tr-agree-label" for="termsAgreement">
                I consent to receive text marketing messages from {{ config.compliance.sender }}. I agree to the <a :href="'https://'+this.config.business_slug+'/terms'" target="_blank">Terms of Use</a>. Msg frequency varies. Reply STOP to opt out any time.
              </label>
            </div>

            <div class="tr-alert tr-alert-red" v-if="hasError" v-html="errorMsg"></div>

            <div class="tr-field-row" style="margin-top: .5rem;" v-if="hasExistingEnrollment">
              <div class="tr-button">
                <button data-element="submit" class="tr-button-submit" :disabled="subscribing">
                  <span v-if="!subscribing && config.styles && config.styles.buttons.accountExists.text">{{ config.styles.buttons.accountExists.text }}</span>
                  <span v-else-if="!subscribing">Resubscribe</span>
                  <span v-else-if="subscribing">Submitting...</span>
                </button>
              </div>
            </div>

            <div class="tr-field-row" v-if="!hasExistingEnrollment">
              <div class="tr-button">
                <button data-element="submit" class="tr-button-submit" :disabled="subscribing">
                  <span v-if="!subscribing && config.styles && config.styles.buttons.submit.text">{{ config.styles.buttons.submit.text }}</span>
                  <span v-else-if="!subscribing">Sign Up</span>
                  <span v-else-if="subscribing">Submitting...</span>
                </button>
              </div>
            </div>

          </form>

        </template>

        <template v-if="submitSuccess">

          <div class="tr-alert tr-alert-green">
              <font-awesome-icon class="tr-success-icon" :icon="['fas', 'mobile-alt']"></font-awesome-icon>
              <span class="tr-alert-message">
                <strong>Success!</strong> Check your phone for a message to continue.
              </span>
          </div>

        </template>

        <div style="text-align: center; margin-top: 25px;" v-if="config.business_slug.indexOf('textretailer') > -1">
          <a :href="'https://textretailer.com'+utmParams">
            <img src="https://textretailer-embed.s3.amazonaws.com/v1/img/textretailer-powered-by-logo@x2.png" alt="Powered by TextRetailer" width="135px" />
          </a>
        </div>

      </div>

      <div class="iti-flag" style="display: none;"></div>

    </div>

  </div>

</template>

<style lang="postcss" scoped>

  .tr-container {
    max-width: 700px;
    font-size: 16px;
    margin: 0 auto;
  }

  .tr-container input[type="text"],
  .tr-container input[type="number"],
  .tr-container input[type="email"],
  .tr-container input[type="password"] {
    font-size: 16px;
  }

  .tr-stripe-container >>> stripe-elements::part(stripe) {
    border-radius: 4px;
    box-shadow: none;
    color: #6c757d;
    border: 1px solid #dee2e6;
    padding: .75rem;
  }

  .simple {
    @apply p-5 rounded;
    border: 1px solid #e3e3e3;
    background: #F9FAFB;
  }

  .tr-alert {
    @apply px-4 py-3 mt-5 mb-3 border-0 rounded items-center justify-center;
  }
  .tr-alert-blue {
    background: #cfe2ff;
    border: 1px #b6d4fe solid;
    color: #084298;
  }
  .tr-alert-green {
    background: #d3fbeb;
    border: 1px #10bf7a solid;
    color: #0c905c;
  }
  .tr-alert-red {
    background: #f8d7da;
    border: 1px #f5c2c7 solid;
    color: #842029;
  }

  .tr-alert-link {
    font-weight: 700;
  }
  .tr-alert-blue .tr-alert-link {
    color: #06357a;
  }

  .tr-button-submit {
    @apply px-6 py-3 rounded border-0;
  }
  .tr-button-submit:hover {
    cursor: pointer;
  }
  .tr-button-submit:disabled:hover {
    cursor: default;
  }

  .tr-button-submit {
    border-color: var(--button-submit-bg);
    background-color: var(--button-submit-bg);
    color: var(--button-submit-color);
    padding: 1rem 2.5rem;
    font-size: 1.1rem;
    border-radius: 50rem;
  }

  .tr-button-submit:hover {
    border-color: var(--button-submit-bg-hover);
    background-color: var(--button-submit-bg-hover);
    color: var(--button-submit-color-hover);
  }

  .tr-button-submit:disabled:hover {
    border-color: var(--button-submit-bg);
    background-color: var(--button-submit-bg);
    color: var(--button-submit-color);
  }

  .tr-button-submit-blue {
    @apply bg-blue-500 text-white;
  }
  .tr-button-submit-blue:hover {
    @apply bg-blue-700;
  }
  .tr-button-submit-blue:disabled:hover {
    @apply bg-blue-500;
  }

  .tr-form {
    @apply flex flex-col;
  }

  .tr-form * {
    box-sizing: border-box;
  }

  .tr-header-container {
    @apply mt-4 mb-8;
  }

  .tr-header {
    @apply text-center mt-0 mb-3;
    color:#4d4d4d;
    font-size:27px;
    font-weight:700;
  }

  .tr-header small {
    font-weight: 400;
  }

  .tr-subheader {
    @apply text-center mt-2 mb-2;
    color: #686868;
    font-size: 18px;
  }

  .tr-field-row {
    margin-right: -12px;
    margin-left: -12px;
    @apply flex w-full flex-wrap;
  }

  .tr-field {
    position: relative;
    width: 100%;
    padding-right: 12px;
    padding-left: 12px;
    @apply mt-4;
  }

  .tr-section-header {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    color: #4d4d4d;
    @apply mt-8 mb-0;
  }

  .tr-phone,
  .tr-card,
  .tr-button {
    padding-right: 12px;
    padding-left: 12px;
    max-width: 525px;
    @apply flex-grow w-full;
  }

  .tr-button {
    @apply mt-4;
  }

  .tr-card {
    @apply mt-4;
  }

  .tr-button-submit {
    @apply mt-2 w-full;
  }

  .tr-agreement {
    @apply flex w-full mt-4;
    padding-left: 1.25rem;
  }

  .tr-agree-input {
    @apply mr-2;
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
  }

  .tr-agree-label {
    @apply text-gray-700;
    font-size: .85rem;
  }

  .tr-agree-label a {
    @apply text-gray-500;
    text-decoration: none;
  }

  .tr-field-row input[type=text] {
    @apply w-full p-3;
  }

  .tr-flex-between{
    margin-right: 12px;
    margin-left: -12px;
    @apply flex justify-between;
  }

  .tr-label {
    display:block;
    margin-bottom: .25rem;
  }

  .tr-form-control {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .45rem .9rem;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  }

  .tr-checkbox, .tr-radio {
    margin-left: 0;
    margin-right: 6px;
  }

  @media (min-width: 992px) {
    .tr-field {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .tr-field-med {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33%;
      flex: 0 0 33%;
      max-width: 33%;
    }

    .tr-field-small {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }

    .tr-field-full {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

  }

  @media only screen and (min-width: 550px) {

    .simple {
      @apply p-6;
      border: 1px solid #e3e3e3;
      background: #F9FAFB;
    }

    .tr-field-row {
      @apply flex-nowrap;
    }

    .tr-button-submit {
      @apply mt-0 w-auto;
    }

    .tr-field:last-child {
      padding-right: 0
    }

  }

  @-webkit-keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  @keyframes ring {
    0% {
      -webkit-transform: rotate(-15deg);
      -ms-transform: rotate(-15deg);
      transform: rotate(-15deg);
    }

    2% {
      -webkit-transform: rotate(15deg);
      -ms-transform: rotate(15deg);
      transform: rotate(15deg);
    }

    4% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    6% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    8% {
      -webkit-transform: rotate(-22deg);
      -ms-transform: rotate(-22deg);
      transform: rotate(-22deg);
    }

    10% {
      -webkit-transform: rotate(22deg);
      -ms-transform: rotate(22deg);
      transform: rotate(22deg);
    }

    12% {
      -webkit-transform: rotate(-18deg);
      -ms-transform: rotate(-18deg);
      transform: rotate(-18deg);
    }

    14% {
      -webkit-transform: rotate(18deg);
      -ms-transform: rotate(18deg);
      transform: rotate(18deg);
    }

    16% {
      -webkit-transform: rotate(-12deg);
      -ms-transform: rotate(-12deg);
      transform: rotate(-12deg);
    }

    18% {
      -webkit-transform: rotate(12deg);
      -ms-transform: rotate(12deg);
      transform: rotate(12deg);
    }

    20% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .tr-faa-ring-container {
    width: 100%;
    text-align: center;
  }

  .tr-success-icon {
    float: left;
    margin-right: .5rem;
    color: #0c905c;
    width: 25px;
    height: 25px;
    -webkit-animation: ring 3s ease infinite;
    animation: ring 3s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
    @apply mt-0 mb-0 text-center;
  }

</style>

<style scoped>
  @import "../node_modules/vue-phone-number-input/dist/vue-phone-number-input.css";
</style>

<style>
  /*@import "/assets/styles/override.css";*/
  /* Pull image from remote server */
  .iti-flag[data-v-46e105de]{background-image:url('https://textretailer-embed.s3.amazonaws.com/v1/img/flags_1623688855.png') !important}
</style>
// https://itnext.io/vuidget-how-to-create-an-embeddable-vue-js-widget-with-vue-custom-element-674bdcb96b97
// https://medium.com/@royprins/get-started-with-vue-web-components-593b3d5b3200
import Vue from 'vue';

import wrap from '@vue/web-component-wrapper';
import EmbeddedForm from './EmbeddedForm.vue';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faMobileAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faMobileAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);

const WrappedElement = wrap(Vue, EmbeddedForm);

window.customElements.define('textretailer-form', WrappedElement);
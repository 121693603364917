import { render, staticRenderFns } from "./EmbeddedForm.vue?vue&type=template&id=237b5cbe&scoped=true&"
import script from "./EmbeddedForm.vue?vue&type=script&lang=js&"
export * from "./EmbeddedForm.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./EmbeddedForm.vue?vue&type=style&index=0&id=237b5cbe&lang=postcss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./EmbeddedForm.vue?vue&type=style&index=1&id=237b5cbe&scoped=true&lang=css&")
if (style1.__inject__) style1.__inject__(context)
var style2 = require("./EmbeddedForm.vue?vue&type=style&index=2&lang=css&")
if (style2.__inject__) style2.__inject__(context)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "237b5cbe",
  null
  ,true
)

export default component.exports